<template>
  <div class="page1">
    <!--面包屑-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content">
      <h2 class="title">
        <div class="title_icon"></div>
        {{ isEdit ? "编辑" : "添加" }}品牌信息
      </h2>
      <!--搜索条件区域-->
      <div class="Wrapper" @keydown.enter.prevent="submitData">
        <el-form
          label-position="right"
          label-width="110px"
          :model="formInline"
          style="width: 334px; float: left"
          :rules="rules"
          ref="form"
        >
          <el-form-item :label="$t('searchModule.Merchant_affiliation')" prop="operationId">
            <el-select
              :disabled="isEdit"
              v-model.trim="formInline.operationId"
              filterable
              size="15"
            >
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in tenantList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Brand_Name')" prop="brandFullName">
            <el-input
              :maxlength="20"
              v-model.trim="formInline.brandFullName"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="品牌简称" prop="brandName">
            <el-input
              :maxlength="20"
              v-model.trim="formInline.brandName"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="品牌编码" prop="brandCode">
            <el-input
              :maxlength="20"
              v-model.trim="formInline.brandCode"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.address')" prop="address">
            <el-input
              :maxlength="40"
              type="textarea"
              v-model.trim="formInline.address"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.contacts')" prop="contacts">
            <el-input
              :maxlength="20"
              v-model.trim="formInline.contacts"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="telephone">
            <el-input
              maxlength="11"
              v-model.trim="formInline.telephone"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.mailbox')" prop="email">
            <el-input
              :maxlength="20"
              v-model.trim="formInline.email"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.remarks')" prop="mark">
            <el-input
              :maxlength="100"
              type="textarea"
              v-model.trim="formInline.mark"
              placeholder="输入内容"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: left; margin-left: 8.5%">
        <el-button type="primary" @click="submitData" style="width: 88px">{{ $t('button.preservation') }}</el-button>
        <el-button type="" @click="$router.go(-1)" style="width: 88px">取消</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "hello",
  data() {
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value)) {
        callback(new Error("手机号格式不正确!"));
      } else {
        callback();
      }
    };
    let checkCode = (rule, value, callback) => {
      if (!/^[a-z0-9]+$/i.test(value)) {
        callback(new Error("编码格式不正确!"));
      } else {
        callback();
      }
    };
    return {
      parkName: "",
      dis: false,
      lnglat: "",
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      workStateAll: [],
      parkTypeAll: [],
      parkList: "",
      areaList: [],
      tenantList: [],
      isEdit: false,
      loading: false,
      startTime: "",
      formInline: {
        operationId: "",
        brandFullName: "",
        brandName: "",
        brandCode: "",
        address: "",
        contacts: "",
        telephone: "",
        email: "",
        mark: "",
      },
      rules: {
        operationId: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        brandFullName: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        brandName: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        brandCode: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          { validator: checkCode, trigger: "blur" },
        ],
        address: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        contacts: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        telephone: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          { validator: checkPhone, trigger: "blur" },
        ],
        email: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    queryDetail() {
      this.$axios.get("/acb/2.0/brand/detail/" + this.$route.query.brandId).then((res) => {
        if (res.state == 0) {
          for (let i in this.formInline) {
            this.formInline[i] = res.value[i];
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    submitData() {
      let url = this.isEdit ? "/acb/2.0/brand/update" : "/acb/2.0/brand/add";
      let method = "post";
      let data = {};
      if (this.isEdit) {
        data = {
          brandId: this.$route.query.brandId,
          ...this.formInline,
        };
      } else {
        data = this.formInline;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$axios[method](url, {
            data: data,
          }).then((res) => {
            if (res.state == 0) {
              this.$alert("操作成功！", this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              }).then(() => {
                this.$router.go(-1);
              });
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
        }
      });
    },
  },
  components: {},
  created() {
    if (this.$route.query.brandId) {
      this.isEdit = true;
      this.queryDetail();
    }
    this.getTenantList();
  },
  mounted() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.Wrapper
  overflow hidden
.breadcrumb
  height: 35px;
.content
  background: #FFFFFF;
  overflow: hidden;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;
.uploadPic
  float: left;
  margin-left: 150px;
</style>
<style>
.avatar-uploader2 .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader2 .el-upload:hover {
  border-color: #0f6eff;
}
.avatar-uploader-icon2 {
  font-size: 28px;
  color: #8c939d;
  width: 295px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar2 {
  width: 295px;
  height: 178px;
  display: block;
}
</style>
