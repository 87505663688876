var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content" }, [
      _c("h2", { staticClass: "title" }, [
        _c("div", { staticClass: "title_icon" }),
        _vm._v(" " + _vm._s(_vm.isEdit ? "编辑" : "添加") + "品牌信息 "),
      ]),
      _c(
        "div",
        {
          staticClass: "Wrapper",
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              $event.preventDefault()
              return _vm.submitData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { width: "334px", float: "left" },
              attrs: {
                "label-position": "right",
                "label-width": "110px",
                model: _vm.formInline,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Merchant_affiliation"),
                    prop: "operationId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.isEdit,
                        filterable: "",
                        size: "15",
                      },
                      model: {
                        value: _vm.formInline.operationId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "operationId",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.operationId",
                      },
                    },
                    _vm._l(_vm.tenantList, function (value) {
                      return _c("el-option", {
                        key: value.operationId,
                        attrs: {
                          label: value.operationName,
                          value: value.operationId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Brand_Name"),
                    prop: "brandFullName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20, placeholder: "请输入内容" },
                    model: {
                      value: _vm.formInline.brandFullName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "brandFullName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.brandFullName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "品牌简称", prop: "brandName" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20, placeholder: "请输入内容" },
                    model: {
                      value: _vm.formInline.brandName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "brandName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.brandName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "品牌编码", prop: "brandCode" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20, placeholder: "请输入内容" },
                    model: {
                      value: _vm.formInline.brandCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "brandCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.brandCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.address"),
                    prop: "address",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: 40,
                      type: "textarea",
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.formInline.address,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "address",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.contacts"),
                    prop: "contacts",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20, placeholder: "请输入内容" },
                    model: {
                      value: _vm.formInline.contacts,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "contacts",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.contacts",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "电话", prop: "telephone" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "11", placeholder: "请输入内容" },
                    model: {
                      value: _vm.formInline.telephone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "telephone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.telephone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.mailbox"),
                    prop: "email",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20, placeholder: "请输入内容" },
                    model: {
                      value: _vm.formInline.email,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "email",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.remarks"),
                    prop: "mark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: 100,
                      type: "textarea",
                      placeholder: "输入内容",
                    },
                    model: {
                      value: _vm.formInline.mark,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "mark",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.mark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "left", "margin-left": "8.5%" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "88px" },
              attrs: { type: "primary" },
              on: { click: _vm.submitData },
            },
            [_vm._v(_vm._s(_vm.$t("button.preservation")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "88px" },
              attrs: { type: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }